<template>
  <f7-app :params="f7params">

    <!-- Left panel with cover effect-->
    <f7-panel left cover>
      <f7-page>
        <f7-navbar title="MobiPolter"></f7-navbar>
        <br>
        <center><img src="static/icons/128x128.png" /></center>
        <f7-block-title>Menu</f7-block-title>
        <f7-list>
          <f7-list-item panel-close title="Stehenderfassung" link="/standing/"></f7-list-item>
          <f7-list-item v-if="!$device.iphone" panel-close
            title="Polterberechnung" link="/objectdetection/">
            <f7-icon v-if="!modules.includes('OD')" material="lock"></f7-icon>
            <f7-icon v-if="modules.includes('OD')" material="lock_open"></f7-icon>
          </f7-list-item>
          <f7-list-item panel-close title="Export" link="/export/"></f7-list-item>
        </f7-list>
        <f7-block-title>Einstellungen</f7-block-title>
        <f7-list>
          <f7-list-item panel-close title="Einstellungen" link="/settings/"></f7-list-item>
          <f7-list-item panel-close title="Update" @click='update()'></f7-list-item>
          <f7-list-item v-if="!$device.iphone" panel-close title="Bluetooth"
            link="/bluetooth/">
            <f7-icon v-if="!modules.includes('FO')" material="lock"></f7-icon>
            <f7-icon v-if="modules.includes('FO')" material="lock_open"></f7-icon>
          </f7-list-item>
        </f7-list>

        <f7-block-title>Dokumente</f7-block-title>
        <f7-list>
          <f7-list-item panel-close title="Qualitätsmerkmale" link="/static/docs/holz-qualitaetsmerkmale.pdf"
            target="_blank" external></f7-list-item>
          <f7-list-item panel-close title="Pocket Card" link="static/docs/pocketcard_2024_25.pdf" target="_blank"
            external></f7-list-item>
          <f7-list-item panel-close title="About" link="/about/"></f7-list-item>
        </f7-list>
        <f7-block>Version 1.0 <br> (galaxy tab s8)</f7-block>
      </f7-page>
    </f7-panel>

    <f7-views tabs class="safe-areas">
      <f7-view id="view-home" main init tab tab-active url="/"></f7-view>
      <f7-view id="view-settings" name="settings" tab url="/karte/"></f7-view>
    </f7-views>


    <f7-sheet class="login-sheet" style="height:auto; --f7-sheet-bg-color: #fff;" backdrop :opened="sheetOpened"
      @sheet:closed="sheetOpened = false;" ref="loginscreen">
      <f7-page-content>
        <f7-block-title>Login</f7-block-title>
        <f7-list form>
          <f7-list-input type="text" name="username" placeholder="Benutzername" :value="username"
            @input="username = $event.target.value"></f7-list-input>
          <f7-list-input type="password" name="password" placeholder="Passwort" :value="password"
            @input="password = $event.target.value"></f7-list-input>
        </f7-list>
        <f7-list>
          <f7-block-footer>
            {{ status }}
          </f7-block-footer>
        </f7-list>
        <f7-block>
          <f7-row>
            <f7-col width="20" />
            <f7-col width="60"><f7-button round fill title="Sign In" @click="login">Login</f7-button></f7-col>
            <f7-col width="20" />
          </f7-row>
        </f7-block>
      </f7-page-content>
    </f7-sheet>
  </f7-app>
</template>
<script>

import routes from '../js/routes.js';

export default {
  data() {
    return {
      // Framework7 Parameters
      f7params: {
        name: 'MobiPolter', // App name
        theme: 'auto', // Automatic theme detection
        routes: routes,
        serviceWorker: {
          path: '/service-worker.js',
        },
      },
      // Login Variables
      //url: 'https://mobipolter.szsolutions.ch',
      url: process.env.VUE_APP_API_URL,
      username: localStorage.user,
      user_id: localStorage.user_id,
      modules: JSON.parse(localStorage.modules || '[]'),
      password: null,
      status: '',
      sheetOpened: false
    }
  },
  methods: {
    update() {
      caches.keys().then(function (cacheNames) {
        return Promise.all(
          cacheNames.filter(function (cacheName) {
          }).map(function (cacheName) {
            return caches.delete(cacheName);
          })
        );
      })
      window.location.reload();
    },
    async login() {
      var that = this
      this.sheetOpened = true
      this.$http({ url: this.url + '/login', data: { email: this.username, password: this.password }, method: 'POST', withCredentials: true })
        .then(resp => {
          const token = resp.data.token
          this.user_id = resp.data.user.id
          localStorage.setItem('token', token)
          //that.$http.defaults.headers.common.Authorization = token
          this.status = 'Login erfolgreich'
          this.getModules();
          this.$root.checkpolters();
          this.sheetOpened = false
        })
        .catch(err => {
          this.status = err
          this.loginfailure = true
          localStorage.removeItem('token')
          localStorage.removeItem('user')
          localStorage.removeItem('user_id')
          localStorage.removeItem('modules')
        })
    },
    async getModules() {
      this.$http({ url: this.url + '/usermodul/' + this.user_id, method: 'GET', withCredentials: true })
        .then(resp => {
          this.modules = resp.data
        })
        .catch(err => {
          this.status = err
          localStorage.removeItem('modules')
        })
    }
  },
  watch: {
    username(username) {
      localStorage.user = username;
    },
    user_id(id) {
      localStorage.user_id = id;
    },
    modules(modules) {
      localStorage.modules = JSON.stringify(modules);;
    }
  },
  mounted() {
    this.$f7ready((f7) => {
      if (this.user_id > 0) {
        this.getModules();
      }
    });
  }
}
</script>